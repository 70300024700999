import React from 'react';
import './heroBannerMobile.styles.css';
import Evan from '../../AvatarImages/Evan-sm.png';
import Chris from '../../AvatarImages/Chris-sm.png';
import Susan from '../../AvatarImages/Susan-sm.png';
import GreenDot from '../../AvatarImages/GreenDot-sm.png';
import PurpleDot from '../../AvatarImages/PurpleDot-sm.png';


export const HeroBannerMobile = () => {

    return (
    <div className="HeroBannerMobile">
        <p className="titleMobile">StayAwakeClub.io</p>
        <p className="subtitleMobile">A webpage that keeps your computer awake</p>
        <div className="AvatarContainerMobile">
            <div className="AvatarMobile susan">
            <img src={Susan} alt="Susan" />
  
                <div className="dotContainerMobile">
                    <img alt="Slack Status Dot" className="greenDotMobile" src={GreenDot} alt="GreenDot" />
                    <img className="purpleDotMobile" src={PurpleDot} alt="PurpleDot" />
                </div>
           
            </div>

            <div className="AvatarMobile chris">
            <img src={Chris} alt="Chris" />
     
                <div className="dotContainerMobile">
                    <img alt="Teams Status Dot" className="greenDotMobile" src={GreenDot} alt="GreenDot" />
                    <img className="purpleDotMobile" src={PurpleDot} alt="PurpleDot" />
                </div>
           
            </div>

            <div className="AvatarMobile evan">
            <img src={Evan} alt="Evan" />
            
                <div className="dotContainerMobile">
                    <img alt="Slack Status Dot" className="greenDotMobile" src={GreenDot} alt="GreenDot" />
                    <img className="purpleDotMobile" src={PurpleDot} alt="PurpleDot" />
                </div>
           
            </div>
       
        
        

        </div>
    </div>
    )
}