import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import './footerMobile.styles.css';

export const FooterMobile = () => {
   

  return (
    <div className="footerMobile">
        <p className="footerTitleMobile"> 
        Don’t mess with your YouTube algo
            </p>
            <a href="https://twitter.com/SAClubPrez" target="_blank">
            <FontAwesomeIcon className="twitter" icon={faTwitter} />
            </a>
    </div>
  );
}
