import React, {useState} from 'react';
import './heroBanner.styles.css';
import Evan from '../../AvatarImages/Evan.png';
import Chris from '../../AvatarImages/Chris.png';
import Susan from '../../AvatarImages/Susan.png';
import GreenDot from '../../AvatarImages/GreenDot.png';
import PurpleDot from '../../AvatarImages/PurpleDot.png';
import Chrome from '../../AvatarImages/Chrome.png';
import Slack from '../../AvatarImages/slack-logo-icon.png'
import { css } from "@emotion/react";
import PropagateLoader from "react-spinners/PropagateLoader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const HeroBanner = (hasTimerExpired) => {
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#ffffff");
    const [buttonClicked, setButtonClicked] = useState(false);

  const clickedJoinClub = () => {
    navigator.clipboard.writeText("stayawakeclub.io");
    setButtonClicked(!buttonClicked);
  }

    return (
    <div className="HeroBanner">
            <p className="title">StayAwakeClub.io</p>    

        <div className="subtitleContainer">
        <p className="subtitle">Your computer will stay awake while this tab is open</p>
        <img className="chrome" src={Chrome} alt="Chrome" />
        </div>
        {/* <p className="tinytext">Chrome only</p> */}
      
        {hasTimerExpired ?  
        <>
        <div className="spinner">
        <PropagateLoader color={color} loading={loading} css={override} size={15} speedMultiplier={.5} />
        </div>
        </> : <> </>}
        

       
        <div className="AvatarContainer">
           <div className="Avatar">
            <img src={Susan} alt="Susan" />
  
                <div className="dotContainer">
                    <img alt="Slack Status Dot" className="greenDot" src={GreenDot} alt="GreenDot" />
                    <img className="purpleDot" src={PurpleDot} alt="PurpleDot" />
                </div>
           
            </div> 

            <div className="Avatar">
            <img src={Chris} alt="Chris" />
     
                <div className="dotContainer">
                    <img alt="Teams Status Dot" className="greenDot" src={GreenDot} alt="GreenDot" />
                    <img className="purpleDot" src={PurpleDot} alt="PurpleDot" />
                </div>
           
            </div>

            <div className="Avatar">
            <img src={Evan} alt="Evan" />
            
                <div className="dotContainer">
                    <img alt="Slack Status Dot" className="greenDot" src={GreenDot} alt="GreenDot" />
                    <img className="purpleDot" src={PurpleDot} alt="PurpleDot" />
                </div>
           
            </div>
       
        
        

        </div>
        <div className="tipContainer">
        <img className="slack" src={Slack} alt="Slack" />
        <p className="tip">Slack active status tip below ⬇</p>
        </div>
    </div>
    )
}