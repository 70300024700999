import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import './footer.styles.css';

export const Footer = () => {
   

  return (
    <div className="footer">
        <p className="footerTitle"> 
        Built with 🖤 by <a href="https://twitter.com/SAClubPrez" target="_blank">@SAClubPrez</a>
            </p>
            
    </div>
  );
}
