import React, { useState, useEffect } from 'react';
import './middleMobile.styles.css';



export const MiddleMobile = () => {
  const [buttonClicked, setButtonClicked] = useState(false);

  const clickedJoinClub = () => {
    navigator.clipboard.writeText("stayawakeclub.io");
    setButtonClicked(!buttonClicked);
  }

    return (
    
        <div className="mobileMiddleContainer">
          <div className="stopSign">🛑</div>
           <p className="titleMiddleMobile">Sorry, this club is exclusive.</p>
            <p className="subtitleMiddleMobile">Desktop computers only.</p>
            <button className='joinClubButton' data-tip data-for="registerTip" onClick={clickedJoinClub} >{buttonClicked ? 'Url Copied' : "Join The Club"}</button>
      </div>
   
    )
}

