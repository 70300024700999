import React from 'react';
import TabReloader from '../../AvatarImages/TabReloader.png';
import HowToSAC from '../../AvatarImages/howtoSAC.png';
import './keepGreen.styles.css';

export const KeepGreen = () => {
   

  return (
    <>
    <div className="KeepGreenTitle1">
       Want to keep your Slack/Teams status active forever?
    </div>
    <div className="listContainer">

    <p className="list">1. Add the <a style={{ color: '#766df4'}} href="https://chrome.google.com/webstore/detail/tab-reloader-page-auto-re/dejobinhdiimklegodgbmbifijpppopn?hl=en"> Tab Reloader</a> chrome extension to your browser.</p>
    <p className="list">2. Open stayawakeclub.io and the Slack/Teams browser version at the same time (see blow).</p>
    <p className="list">3. Using the TabReloader extension have the Slack or Teams tab reload every 5 mins.</p>
    {/* <img className= "tabReloaderImage" src={TabReloader} alt="TabReloader" /> */}
    </div>
    {/* <p className="KeepGreenTitle2">Set up your window like this:</p> */}
    {/* <p className="footerTitle">... and your dot will stay green forever!</p> */}

    <img className= "howtoSAC" src={HowToSAC} alt="HowToSAC" />
    </>
  );
}
