import React, { useState, useEffect } from 'react';
import './App.css';
import useStayAwake from "use-stay-awake";
import { useMediaQuery } from 'react-responsive';
import { TabletMobile } from "./deviceViews/tablet-mobile/component";
import { HeroBanner } from './components/heroBanner/component';
import { Footer } from './components/footer/component';
import { useCountdown } from './hooks/useCountdown.jsx';
import {CountdownTimer} from './components/timer/component.jsx';
import ReactTooltip from "react-tooltip";
import { KeepGreen } from './components/keepGreen/component';
import './components/stayAwake/stayAwake.styles.css'
// Analytics
import ReactGA from "react-ga4";
import mixpanel from 'mixpanel-browser';
const TRACKING_ID = "G-R8GBYGL1BH"; // Google Analytics Tracking ID
ReactGA.initialize(TRACKING_ID);
mixpanel.init('71ccfe3a17fe17466dfdb622619ecfe2', {debug: true}); 


function App() {
  const device = useStayAwake();
  const [targetDate, setTargetDate] = useState(0);
  const [hours, minutes, seconds] = useCountdown(targetDate);
  const [activeButtonID, setActiveButtonID] = useState(5);
  const [animation, setAnimation] = useState(0)
  const [hasTimerExpired, setHasTimerExpired] = useState(false);
  const [countButtonClicks, setCountButtonClicks] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);

  const clickedJoinClub = () => {
    navigator.clipboard.writeText("stayawakeclub.io");
    setButtonClicked(!buttonClicked);
  }


  const isLaptopOrDesktop = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });

  // prevent sleeping on first render
   useEffect(() => {
     console.log("preventing sleep on intial render");
     ReactGA.send("pageview");
     mixpanel.track('pageview');
    device.preventSleeping();
      },[]);

  useEffect (() => {
    // will be triggered at 1 min
    if(minutes + seconds === 1){
      console.log("setting Has timer expired");
      setHasTimerExpired(true);
    }
  },[hours,minutes,seconds])

  // check if sleep should be allowed
      // hasTimerExpired = True
      // device.canSleep = false
      // any button except "Forever" is selected
  useEffect(() => {
    if(hasTimerExpired && !device.canSleep && activeButtonID !== 5 ){
      console.log('within useEffect that changes to allowSleep');
      device.allowSleeping();
    }
  }, [hours,minutes,seconds, activeButtonID]);


  // renderAnimations
  useEffect(() => {
    renderAnimations();
  }, [activeButtonID]);


  const renderAnimations = () => {
    setAnimation(1);
  }

  const getButtonInfo = (buttonCopy) => {
      let ms = 0;
      let buttonID = 5;

      switch (buttonCopy) {
          case '30 min':
              ms = 30 * 60 * 1000;
              buttonID = 1;
              break
          case '60 min':
              ms = 60 * 60 * 1000;
              buttonID = 2;
              break;
          case '90 min':
              ms = 90 * 60 * 1000;
              buttonID = 3;
              break;
          case '120 min':
              ms = 120 * 60 * 1000;;
              buttonID = 4;
              break;
          default:
              console.log('Forever');
              buttonID = 5;
      }
      return[ms, buttonID];
  }

  const setStayAwakeTimer = (e) => {
    // if "refresh browser tooltip needed"
    // setCountButtonClicks(countButtonClicks + 1)

    // Track button clicks
    ReactGA.event({
      category: 'Timer Button Click',
      action: `${e.target.innerHTML}`,
    });

    mixpanel.track('Button Click', {
      name: "Timer Start",
      timeLength: `${e.target.innerHTML}`
    });

    if(hasTimerExpired){
      setHasTimerExpired(false);
    }
    const [msFromButton, newActiveButtonID]  = getButtonInfo(e.target.innerHTML);
      const NOW_IN_MS = new Date().getTime();
      setActiveButtonID(newActiveButtonID);
      // adding 2000 extract to make the countdown start at top of minute
      setTargetDate(NOW_IN_MS + msFromButton + 1000);

      if(device.canSleep){
        device.preventSleeping();
      }

  }

  return (

        <div className="App">
          {isLaptopOrDesktop ?
          <>
              <HeroBanner hasTimerExpired={hasTimerExpired} />
              <div className="stayAwakeContainer">
                      <div>
                        <p className="stayAwakeTitle">Keep your computer awake for... </p>
                          <div className="buttonContainer">
                          {/* <span>
                            {device.canSleep
                              ? "Device is allowed to sleep"
                              : "Device is not allowed to sleep"}
                          </span> */}
                              <button  id={activeButtonID === 1 ? "active" : ""} className='timerButton' onClick={setStayAwakeTimer} animation={activeButtonID === 1 ? 1 : 0} data-tip data-for="refreshTip">30 min</button>
                              <button id={activeButtonID === 2 ? "active" : ""} className="timerButton" onClick={setStayAwakeTimer} animation={activeButtonID === 2 ? 1 : 0} data-tip data-for="refreshTip">60 min</button>
                              <button id={activeButtonID === 3 ? "active" : ""} className="timerButton" onClick={setStayAwakeTimer} animation={activeButtonID === 3 ? 1 : 0} data-tip data-for="refreshTip">90 min</button>
                              <button id={activeButtonID === 4 ? "active" : ""} className="timerButton" onClick={setStayAwakeTimer} animation={activeButtonID === 4 ? 1 : 0} data-tip data-for="refreshTip">120 min</button>
                              <button id={activeButtonID === 5 ? "active" : ""} className='timerButton' onClick={setStayAwakeTimer} animation={activeButtonID === 5 ? 1 : 0} data-tip data-for="refreshTip">Forever</button>
                          </div>
                          <button className='copylink' onClick={clickedJoinClub} >{buttonClicked ? 'Copied' : "Copy Link"}</button>
                      </div>
                      
                      {/* {countButtonClicks >= 1 ? 
                      <>
                      <ReactTooltip id="refreshTip" place="top" effect="solid">
                        Refresh Your Browser For Best Performance
                      </ReactTooltip>
                      </> : '' */}
                    
                      

                      <div>
                    <CountdownTimer targetDate={targetDate} activeButtonID={activeButtonID} hasTimerExpired={hasTimerExpired} />
                  </div>
                    
                  </div>
                  <div style={{ width: '40%', borderWidth: "1px", borderColor: "#E9E9E9", borderStyle:"solid", height: 0, margin: "auto", marginTop: "50px", marginBottom: "20px"}}></div>
                  <KeepGreen />
              <Footer />
          </> :
              <TabletMobile/>}

        </div>

  );
}

export default App;
