import React from 'react';
import { useCountdown } from '../../hooks/useCountdown';
import './timer.styles.css';



export const CountdownTimer = ({ targetDate, activeButtonID, hasTimerExpired }) => {
    const [hours, minutes, seconds] = useCountdown(targetDate);
  
// console.log("activeBUttonID", activeButtonID);

const renderTimer = () => {
 /* If timer is negatiive and other button is selected show "00:00" */
 if(hours + minutes + seconds <=0 && activeButtonID !== 5 && hasTimerExpired){
  return (
    <div className='countDownTimer2'>
      <p className='zeros'>00:00</p>
      <p className='countDownTimerSubtext'> ⚠️ Your computer is allowed to fall asleep now ⚠️</p>
      </div>
  )
 }


  /* If time is positiive and single digit secs show the timer */
  if(hours + minutes + seconds > 0 && seconds < 10){

    return(
      <div className='countDownTimer1'>
          <p>{hours}</p>
          <p>:</p>
          <p>{minutes}</p>
          <p>:</p>
          <p>0</p>
          <p>{seconds}</p>
          </div>
    )
  }
  /* If time is positiive, show the timer */
  if(hours + minutes + seconds > 0 && seconds >= 10){
  return(
    <div className='countDownTimer1'>
        <p>{hours}</p>
        <p>:</p>
        <p>{minutes}</p>
        <p>:</p>
        <p>{seconds}</p>
        </div>
        )
  }

 /* If time is negative and "Forever" is selected show nothing  */
 if(hours + minutes + seconds <= 0){
  return (
    <div></div>
  )
}

}

 

  return (
     renderTimer()
  );
}

