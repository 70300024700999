import React from "react";
import { HeroBannerMobile } from "../../components/heroBannerMobile/componet";
import { MiddleMobile } from "../../components/middleMobile/component";
import { FooterMobile } from "../../components/footerMobile/componenst";


export const TabletMobile = () => {
  return (
    <>
      <HeroBannerMobile />
      <MiddleMobile />
      <FooterMobile />
      </>
    
  );
};